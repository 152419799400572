// app/providers.tsx
'use client';

import { HeroUIProvider } from "@heroui/react";
import { Provider } from 'react-redux';
import store from '../store/index';
import { ClerkProvider } from '@clerk/nextjs';

export function Providers({ children }: { children: React.ReactNode }) {
    return (
        <ClerkProvider publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}>  
                <Provider store={store}>
                    <HeroUIProvider>{children}</HeroUIProvider>
                </Provider>
            </ClerkProvider>
    );
}
